import React from "react";


const JssReceipt = () => {
    return (
        <div>

        </div>
    )
}

export default JssReceipt