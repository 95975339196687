import { useLocation } from "react-router-dom"
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { useAppContext } from "../Context";
import generatePDF from "react-to-pdf"
import { Icon } from '@iconify/react';


const StepThree = () => {
    const { studentDetails } = useAppContext();
    const location = useLocation()
    const { reference } = location.state
    const { transaction } = location.state
    const { message } = location.state
    const studentClass = studentDetails?.class

    const [successMessage, setSuccessMessage] = useState()
    const PaymentRef = useRef()
    // const amount = 0

    const AdmissionAmount = () => {
        let admissionFee = 0;
        if (studentClass === "Jss One") {
            admissionFee = 12000;
        } else if (studentClass === "Jss Two") {
            admissionFee = 14000;
        } else if (studentClass === "Jss Three") {
            admissionFee = 14000;
        } else if (studentClass === "Sss One") {
            admissionFee = 16000;
        } else if (studentClass === "Sss Two") {
            admissionFee = 18000;
        } else if (studentClass === "Sss Three") {
            admissionFee = 18000;
        }
        return admissionFee // Convert to kobo
    };



    useEffect(() => {
        const handleSubmitReference = async () => {
            try {
                const response = await axios.post('https://dbmsc-server.onrender.com/api/paymentreference', {
                    reference: reference,
                    transaction: transaction,
                });
                console.log(reference)
                console.log(transaction)
                setSuccessMessage(response.data.message);
                console.log('Reference ID sent successfully');
            } catch (error) {
                console.error("Error submitting reference:", error);
            }
        };
        handleSubmitReference();
    }, [reference, transaction]);




    return (
        <div className="m-0 p-0" style={{ height: "100vh", width: "100%" }} >
            <div style={{}} >
                <div style={{
                    backgroundColor: "white", marginLeft: "20px", marginRight: "20px", marginTop: "-140px",
                    paddingTop: "30px", fontFamily: 'Montserrat, sans-serif',
                }} ref={PaymentRef}>
                    <div className="d-flex justify-content-start"><img style={{ width: '100px', height: '100px', textAlign: "start" }} 
                    src="/images/School_logo.jpg" alt="logo" /></div>
                    <div style={{ textAlign: "start", width: "100%" }} className="d-flex justify-content-between p-2 ">
                        <div>
                            <h3 className="m-0">D'Blossom Model Private Schools</h3>
                            <p className="m-0">Dam-view, Obada Oko, Abeokuta, Ogun State.</p>
                            <p className="m-0">divineblossom999@gmail.com</p>
                            <p className="m-0">07025065593</p>
                        </div>
                        <div>
                            <h3>Admission Form Receipt</h3>
                            <p>Generated on {new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>

                        </div>
                    </div>
                    <div className="p-2 mt-3" style={{ width: "100%" }}>
                        <h5 className="p-2" style={{ textAlign: "start", backgroundColor: "#29176D", color: "white", }}>Payer's Information</h5>
                        <table className="mt-3" style={{ width: "100%", borderCollapse: "collapse", fontSize: "18px" }}>
                            <tbody className="m-3" style={{ width: "100%", textAlign: "start" }}>
                                <tr className="p-5" style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ width: "20%", borderRight: "1px solid #f5f5f5" }}>Name</td>
                                    <td style={{ width: "80%" }}>{studentDetails?.name}</td>
                                </tr>
                                <tr className="m-5" style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Email</td>
                                    <td>{studentDetails?.email}</td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Phone Number</td>
                                    <td>{studentDetails?.phone}</td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Admission Type</td>
                                    <td>Admission into {studentDetails?.class}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="p-2 mt-3" style={{ width: "100%" }}>
                        <h5 className="p-2" style={{ textAlign: "start", backgroundColor: "#29176D", color: "white", }}>Transaction Information</h5>
                        <table className="mt-3" style={{ width: "100%", borderCollapse: "collapse", fontSize: "18px" }}>
                            <tbody className="m-3" style={{ width: "100%", textAlign: "start" }}>
                                <tr className="p-5" style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ width: "20%", borderRight: "1px solid #f5f5f5" }}>Payment Status</td>
                                    <td style={{ width: "80%", color: "green" }}>{message}</td>
                                </tr>
                                <tr className="m-5" style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Reference ID</td>
                                    <td>{reference}</td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Transaction ID</td>
                                    <td>{transaction}</td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #f5f5f5" }}>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Payment Purpose</td>
                                    <td>Student Admission Form </td>
                                </tr>
                                <tr>
                                    <td style={{ borderRight: "1px solid #f5f5f5" }}>Payment Acknowledged</td>
                                    <td>By Bursar (Dr. Mrs Akapo)</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ border: "1px solid #f5f5f5", alignItems: "center" }} className="d-flex justify-content-between m-5 p-2">
                        <div style={{ alignSelf: "center", fontSize: "18px" }}>Total Amount Paid</div>
                        <div style={{ fontSize: "25px", fontWeight: "bold" }}>{AdmissionAmount()}</div>
                    </div>
                    <div style={{ width: "100%", backgroundColor: "#fbeeee", textAlign: "start", alignItems: "center" }} className="footer m-0 p-3 d-flex">
                        <Icon icon="healthicons:info-outline" width="18px" height="18px" /> Kindly download a copy of the receipt for reference.
                    </div>
                </div>
            </div>




            <button className="btn btn-primary m-3 p-2" style={{ fontWeight: "bold", borderRadius: "20px", width: "50%" }}
                onClick={() => {
                    generatePDF(PaymentRef,{
                        filename: "admissionform.pdf"
                    })
                }}
            >Download</button>
        </div>
    )
}
export default StepThree