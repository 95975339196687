import axios from "axios";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { useAppContext } from "../components/Context";

export default function UploadOverlay (){
    const [classAssigned, setclassAssigned] = useState('')
    const email = JSON.parse(localStorage.getItem('TeacherUser')).email
    const teacherId = JSON.parse(localStorage.getItem('TeacherUser')).teacherId
    const [term, setTerm] = useState('')
    const [currentSession, SetSession] = useState('')
    const [data, setData] = useState([]);
    const [reports, setReports] = useState([]);
      const [columnArray, setColumn] = useState([]);
      const [values, setValues] = useState([]);
      const [tester, setTester] = useState([]);
      const [teacher, setTeacher] = useState(null);
      const {openOverlay, setOpenOverlay} = useAppContext()
 

  const handleFile = (event) => {
    Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (result) {
        const headerRow = result.data[0];

        if (headerRow) {
          const columnArray = Object.values(headerRow);
          const valuesArray = result.data
            .slice(1)
            .map((row) => Object.values(row));

          setColumn(columnArray);
          setValues(valuesArray);
          setTester(result.data);
          setData(result.data.slice(2));
        }
      },
    });
  };

  console.log(data);

  
  useEffect(() => {
    const fetchTeacherById = async () => {
     
      try {
        const response = await axios.get(`https://dbmsc-server.onrender.com/api/teachers/${email}/${teacherId}`);
        setTeacher(response.data);
        setclassAssigned(response.data.classAssigned)
       
        
      //   setCategory(response.data.category)
        
      } catch (error) {
        console.error('Error fetching teacher by ID:', error);
        // Handle error (display error message, etc.)
      }
    };

    // Fetch data on screen load
    fetchTeacherById();
  }, [email, teacherId]);

  useEffect(() => {
    const fetchData = async () => {
     
      try {
        const response = await axios.get(`https://dbmsc-server.onrender.com/api/term`);
        setTerm(
           response.data[0].term)
           SetSession(response.data[0].session)
       
      } catch (error) {
        console.error('Error fetching registration by surname:', error);
        // Handle error (display error message, etc.)
      }
    };
  
    // Fetch data on screen load
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://dbmsc-server.onrender.com/api/save-results",
        { results: data, selectedClass: classAssigned, term: term, currentSession: currentSession}
      );
      console.log(response);
      
    } catch (error) {
      console.log(error);
    }
  };

return(
    <Wrapper className="d-flex flex-column justify-content-center align-items-center">
    <div className="input-div d-flex flex-column justify-content-center align-items-center">
       
    <input 
        type="file"
        name="file"
        accept=".csv"
        onChange={handleFile}
      ></input>
       <div className="d-flex flex-row justify-content-end">
            <button className="btn btn-danger btn-block" onClick={()=>{
                setOpenOverlay(false)
            }}>cancel</button>
        </div>
    </div>
      {
        data.length > 0 ? (
      <div className="d-flex flex-row gap-2 justify-content-center mt-2">

      <button className="btn btn-info btn-block" onClick={()=>{
        handleSubmit()
        setOpenOverlay(false)
      }}>Submt</button>
      {/* <Link to="/result">
      <button >show</button>
      </Link> */}
      </div>

        ): (
<></>
        )
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`

width:100%;
// background:#f1f1f1;
// position:absolute !important;
// z-index:99;
margin-left: 50px;
margin-top: 30px;
.input-div{
    width: 300px;
    height: 100px !important;
    border: 0px solid grey !important;
}
`