import { useLocation } from "react-router-dom"
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { useAppContext } from "../Context";
import generatePDF from   "react-to-pdf"
import { Icon } from '@iconify/react';

const PayThree = () => {
    const { studentDetails } = useAppContext();
    const location = useLocation()
    const { reference } = location.state
    const { transaction } = location.state
    const { message } = location.state
  
  const surname = studentDetails?.surname
  const newAmount =studentDetails?.amount
  const admission = studentDetails?.admission
  const [oldAmount, setOldAmount] =useState("")

  console.log(reference);
console.log(oldAmount);
console.log(newAmount)

  
    const [successMessage, setSuccessMessage] = useState()
const PaymentRef = useRef()
    // const amount = 0

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch registration by surname
                const registrationResponse = await axios.get(`https://dbmsc-server.onrender.com/api/student/${surname}/${admission}`);
                setOldAmount(registrationResponse.data.amount);

              
              

                // Calculate and update amount
                const amount = Number(registrationResponse.data.amount) + Number(newAmount);
                console.log(surname, admission, amount);
                const updateAmountResponse = await fetch('https://dbmsc-server.onrender.com/registration/update-amount', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ admission, surname, amount })
                });
                if (!updateAmountResponse.ok) {
                    throw new Error('Failed to update amount');
                }
                console.log('Amount updated successfully', amount);
            } catch (error) {
                console.error('Error:', error);
                // Handle error (display error message, etc.)
            }
        };

        fetchData();
    }, [surname, admission, newAmount, reference, transaction]);
    
   

    return (
        <div className="m-0"  style={{height: "100vh"}}>

<div style={{backgroundColor: "white", width:"100wh",  marginLeft: "20px", marginRight: "20px",
 marginTop: "",  paddingTop: "30px", fontFamily: 'Montserrat, sans-serif' }} ref={PaymentRef}>
                <div className="d-flex justify-content-start"><img style={{ width: '100px', height: '100px', textAlign: "start" }} src="/images/School_logo.jpg" alt="logo" /></div>
                <div style={{textAlign: "start"}} className="d-flex justify-content-between p-2 ">
                    <div>
                        <h3 className="m-0">D'Blossom Model Private Schools</h3>
                        <p className="m-0">Dam-view, Obada Oko, Abeokuta, Ogun State.</p>
                        <p className="m-0">divineblossom999@gmail.com</p>
                        <p className="m-0">07025065593</p>
                    </div>
                    <div>
                        <h3>School Fees Receipt</h3>
                        <p>Generated on {new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>

                    </div>
                </div>
                <div className="p-2 mt-3"  style={{width: "100%"}}>
                    <h5 className="p-2" style={{textAlign:"start", backgroundColor: "#29176D", color: "white", }}>Payer's Information</h5>
                    <table className="mt-3" style={{width: "100%",  borderCollapse: "collapse", fontSize:"18px"}}>
                    <tbody className="m-3"  style={{width: "100%", textAlign: "start"}}>
                            <tr className="p-5" style={{borderBottom: "1px solid #f5f5f5"}}> 
                                <td style={{width: "20%", borderRight: "1px solid #f5f5f5"}}>Name</td>
                                <td  style={{width: "50%",}}>{studentDetails?.surname} {studentDetails?.firstname}</td>
                            </tr>
                            <tr className="m-5" style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Admission Number</td>
                                <td>{studentDetails?.admission}</td>
                            </tr>
                            <tr className="m-5" style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Email</td>
                                <td>{studentDetails?.email}</td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Student Class</td>
                                <td>{studentDetails?.class}</td>
                            </tr>
                            <tr className="m-5" style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Phone Number</td>
                                <td>{studentDetails?.phone}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="p-2 mt-3"  style={{width: "100%"}}>
                    <h5 className="p-2" style={{textAlign:"start", backgroundColor: "#29176D", color: "white", }}>Transaction Information</h5>
                    <table className="mt-3" style={{width: "100%",  borderCollapse: "collapse", fontSize:"18px"}}>
                    <tbody className="m-3"  style={{width: "100%", textAlign: "start"}}>
                            <tr className="p-5" style={{borderBottom: "1px solid #f5f5f5"}}> 
                                <td style={{width: "20%", borderRight: "1px solid #f5f5f5"}}>Payment Status</td>
                                <td  style={{width: "50%", color: "green"}}>{message}</td>
                            </tr>
                            <tr className="m-5" style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Reference ID</td>
                                <td>{reference}</td>
                            </tr>
                            <tr style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Transaction ID</td>
                                <td>{transaction}</td>
                            </tr>
                            <tr  style={{borderBottom: "1px solid #f5f5f5"}}>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Payment Purpose</td>
                                <td>School Fees</td>
                            </tr>
                            <tr>
                                <td style={{borderRight: "1px solid #f5f5f5"}}>Payment Acknowledged</td>
                                <td>By Bursar (Dr. Mrs Akapo)</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div  style={{border: "1px solid #f5f5f5", alignItems: "center"}} className="d-flex justify-content-between m-5 p-2"> 
                    <div style={{alignSelf: "center", fontSize:"18px"}}>Total Amount Paid</div>
                    <div style={{fontSize: "25px", fontWeight: "bold"}}>{studentDetails?.amount}</div>
                </div>
                <div  style={{width: "100%", backgroundColor: "#fbeeee", textAlign: "start", alignItems:"center"}} className="footer m-0 p-3 d-flex">
                   <Icon icon="healthicons:info-outline" width="18px" height="18px"/> Kindly download a copy of the receipt for reference.
                </div>
            </div>
           
           
            <button  className="btn btn-primary m-3 p-2" style={{fontWeight: "bold",  borderRadius: "20px", width: "50%"}}
            onClick={()=>{
                generatePDF(PaymentRef, {filename: "schoolfees.pdf"})
            }}
            >Download</button>
        </div>
    )
}
export default PayThree