
import Papa from "papaparse";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styled from "styled-components";
import UploadOverlay from "../custom/uploadOverlay";
import { useAppContext } from "../components/Context";
export default function UploadPage() {
  const [data, setData] = useState([]);
  const [reports, setReports] = useState([]);
  const { openOverlay, setOpenOverlay } = useAppContext()
  const [columnArray, setColumn] = useState([]);
  const [values, setValues] = useState([]);
  const [tester, setTester] = useState([]);
  const [teacher, setTeacher] = useState(null);
  const { students, setStudents } = useAppContext();

  const [classAssigned, setclassAssigned] = useState('')
  const email = JSON.parse(localStorage.getItem('TeacherUser')).email
  const teacherId = JSON.parse(localStorage.getItem('TeacherUser')).teacherId
  const [term, setTerm] = useState('')
  const [currentSession, SetSession] = useState('')




  console.log(classAssigned);
  const handleFile = (event) => {
    Papa.parse(event.target.files[0], {
      header: false,
      skipEmptyLines: true,
      complete: function (result) {
        const headerRow = result.data[0];

        if (headerRow) {
          const columnArray = Object.values(headerRow);
          const valuesArray = result.data
            .slice(1)
            .map((row) => Object.values(row));

          setColumn(columnArray);
          setValues(valuesArray);
          setTester(result.data);
          setData(result.data.slice(2));
        }
      },
    });
  };

  console.log(data);


  useEffect(() => {
    const fetchTeacherById = async () => {
      getResults()
      try {
        const response = await axios.get(`https://dbmsc-server.onrender.com/api/teachers/${email}/${teacherId}`);
        setTeacher(response.data);
        setclassAssigned(response.data.classAssigned)
        const myStudents = await axios.get(`https://dbmsc-server.onrender.com/api/getMyStudents/${response.data.classAssigned}`)
        setStudents(myStudents.data)
        console.log(students)


        //   setCategory(response.data.category)

      } catch (error) {
        console.error('Error fetching teacher by ID:', error);
        // Handle error (display error message, etc.)
      }
    };

    // Fetch data on screen load
    fetchTeacherById();
  }, [email, teacherId]);
  console.log(students)

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(`https://dbmsc-server.onrender.com/api/term`);
        setTerm(
          response.data[0].term)
          SetSession(response.data[0].session)

      } catch (error) {
        console.error('Error fetching registration by surname:', error);
        // Handle error (display error message, etc.)
      }
    };

    // Fetch data on screen load
    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://dbmsc-server.onrender.com/api/save-results",
        { results: data, selectedClass: classAssigned, term: term, currentSession: currentSession }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const getResults = async () => {
    try {
      const response = await axios.get(
        `https://dbmsc-server.onrender.com/api/studentsresults/SECOND/JSS 3`
      );
      setReports(response.data.results[0].results);
    } catch (error) {
      console.log(error);
    }
  };
  const admissionNumberToFind = "0030";

  const studentData = reports.find((row) => row[0] === admissionNumberToFind);
  console.log(reports)
  console.log(studentData);

  return (
    <Wrapper className="">
      
      <div style={{alignItems: "end", textAlign:"end", margin: "20px", width: "30%"}} className="flex flex-row justify-content-end p-2 btn-div" >
        <button className="btn btn-info btn-block" style={{alignSelf: "end", fontWeight: "bold"}} onClick={() => {
          setOpenOverlay(true)
        }}>Upload Results</button>
        <div>

         {
        openOverlay ? <UploadOverlay /> : ""
      }
        </div>
      </div>
      <div style={{marginTop: "80px"}} >
        {
          reports?  (
            <table className="table">
              <thead>
                <tr style={{fontWeight: "bold"}}>
                  <td>Admission Number</td>
                  <td>Surname</td>
                  <td>First Name</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={students._id}>
    
                    <td>{student.admission}</td>
                    <td>{student.surname}</td>
                    <td>{student.firstname}</td>
                    <td><a style={{textDecoration: "none", fontWeight: "bold", color: "blue"}} href={`/teacher/result/${student.admission}`}>view</a></td>
                  </tr>
                ))}
              </tbody>
            </table>
    
              )  :( " no reports uploaded yet")
        }
      </div>
     
    </Wrapper>
  );
}

const Wrapper = styled.div`
height: 100vh;
.input-div{
    width: 500px;
    height: 500px;
    border: 1px solid grey;
}
.table {
 text-align: start;
 width: 90%;
  margin: 20px;
  padding: 20px !important;
}
@media (max-width: 520px) {
  .btn-div {
    width: 50% !important;
  }
}
@media (max-width: 300px) {
  .btn-div {
    width: 70% !important;
  }
  .table {
    padding: 10px !important;
  }
}
`
